
import Http from './Http'

export default {
    async getCustomer(customer_id) {
        return await Http.get(`customers/${customer_id}`)
    },
    async getCustomerOptIns(customer_id) {
        return await Http.get(`customers/optins?customer_id=${customer_id}`);
    },
    async getCustomers(data) {
        return await Http.get(`customers?${data}`)
    },
    async getCustomersByFilter(data) {
        return await Http.get(`/customers/filter?${data}`)
    },
    async addCustomer(data) {
        return await Http.post('customers', data)
    },
    async updateCustomer(customer_id, data) {
        return await Http.patch(`/customers/${customer_id}`, data)
    },
    async updatePhone(phone_id, data) {
        return await Http.patch(`/customers/phone/${phone_id}`, data)
    },
    async primaryEmail(data) {
        return await Http.post(`/customers/email/confirm`, data)
    },
    async updateAddress(address_id, data) {
        return await Http.patch(`/customers/address/${address_id}`, data)
    },
    async addAddress(data) {
        return await Http.post(`/customers/address`, data)
    },
    async addPhone(data) {
        return await Http.post(`/customers/phone`, data)
    },
    async removeAddress(address_id) {
        return await Http.delete(`/customers/address/${address_id}`)
    },
    async removePhone(phone_id) {
        return await Http.delete(`/customers/phone/${phone_id}`)
    },
    async getUserStatuses() {
        return await Http.get(`/customers/statuses`)
    },
    async changeCRMPassword(data) {
        return await Http.patch(`/customers/admin/passwordChange`, data)
    },
    async verifyEmail(data) {
        return await Http.patch(`/customers/email/verify`, data)
    },
    async getCustomersCountries() {
        return await Http.get(`/customers/address/countries`)
    },
    async getCustomersRequests(data) {
        return await Http.get(`/customers/requests?${data}`)
    },
    async resolveCustomersRequest(request_id, data) {
        return await Http.patch(`/customers/requests/${request_id}`, data)
    },
    async deleteCustomer(customer_id) {
        return await Http.delete(`/customers/${customer_id}`)
    },
    async createCustomerFromTicketingData(data) {
        return await Http.post(`/customers/admin/createFromTicketing`, data)
    },
    async addRequest(data) {
        return await Http.post(`/customers/requests`, data)
    },
}


