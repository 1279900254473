<script>
    import Customers from '@/services/Customers';
    import Swal from "sweetalert2";
    import validationMessages from '@/components/validations'
    import {minLength, required, sameAs, maxLength} from "vuelidate/lib/validators";

    export default {
        components:{
            validationMessages
        },
        props: {
            userData: {
                type: Object,
                default: null
            },
        },
        watch: {
            userData: function(newVal) {
                this.id_e = newVal.id;
            }
        },
        data() {
            return {
                id_e: '',
                new_password: '',
                confirm_password: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                csrf_token: localStorage.getItem('csrf_token')
            };
        },
        validations: {
            new_password: {
                required,
                valid: function(value) {
                    const containsUppercase = /[A-Z]/.test(value)
                    const containsLowercase = /[a-z]/.test(value)
                    const containsNumber = /[0-9]/.test(value)
                    return containsUppercase && containsLowercase && containsNumber
                },
                minLength: minLength(6),
                maxLength: maxLength(20),
            },
            confirm_password: {
                required,
                sameAsPassword: sameAs('new_password')
            },
        },
        methods: {

            async changeCRMPassword(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Customers.changeCRMPassword({
                            customer_id: this.id_e,
                            new_password: this.new_password,
                            confirm_password: this.confirm_password,
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg(response);
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },

            resetProps(){
                this.new_password = '';
                this.confirm_password = '';
                this.submitted = false;
            }
        }
    };
</script>

<template>
    <b-modal @hidden="resetProps" v-model="showModal" id="change_password" title="Change Password" title-class="font-18" centered>
        <form @submit.prevent="changeCRMPassword">

            <b-form-group label="New Password" label-for="formrow-new_password-input" class="mb-3">
                <b-form-input
                        v-model.trim="new_password"
                        type="password"
                        id="new_password"
                        aria-describedby="new_password-feedback"
                        :class="{
                      'is-invalid': submitted && $v.new_password.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'New Password'" :validationName="$v.new_password"></validationMessages>
            </b-form-group>

            <b-form-group label="Confirm Password" label-for="formrow-confirm_password-input" class="mb-3">
                <b-form-input
                        v-model.trim="confirm_password"
                        type="password"
                        id="confirm_password"
                        aria-describedby="confirm_password-feedback"
                        :class="{
                      'is-invalid': submitted && $v.confirm_password.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Confirm Password'" :validationName="$v.confirm_password"></validationMessages>
            </b-form-group>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="changeCRMPassword" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change</b-button>
        </template>
    </b-modal>
</template>